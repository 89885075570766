import { css, Global } from '@emotion/react'
import { varKeys as colorVarKeys } from 'components/src/theme/colors'
import { varKeys as typographyVarKeys } from 'components/src/theme/typography'
import { varKeys as utilVarKeys } from 'components/src/theme/utils'

const ThemeOverrides = () => (
    <Global
        styles={css`
            :root {
                ${utilVarKeys.topbarMenuOffset}: 170px;
                ${colorVarKeys.grayDark}: #363636;

                ${colorVarKeys.primary}: #363636;
                ${colorVarKeys.primaryExtra}: #6e7a7b;

                ${colorVarKeys.secondary}: #dc143c;
                ${colorVarKeys.secondaryExtra}: #ee3157;

                ${colorVarKeys.badgeBg}: #dc143c;

                ${colorVarKeys.text}: #363636;
                ${colorVarKeys.textWhite}: #ececec;

                ${colorVarKeys.darkHeadings}: #ececec;

                ${colorVarKeys.onBackground}: #363636;
                ${colorVarKeys.darkBackground}: #363636;

                ${typographyVarKeys.fonts.primary}: 'Roboto Slab', Helvetica,
                    Arial, sans-serif;
                ${typographyVarKeys.fonts.secondary}: 'Roboto Slab', Helvetica,
                    Arial, sans-serif;

                ${colorVarKeys.promoBannerBackground}: var(
                    ${colorVarKeys.sale}
                );

                ${colorVarKeys.footerTopBackground}: #384649;
                ${colorVarKeys.footerMainBackground}: #384649;

                --membercard-bg-color: var(${colorVarKeys.primaryExtra});
                --membercard-logo-color: var(${colorVarKeys.white});
                --membercard-bar-color: var(${colorVarKeys.grayDark});
            }
        `}
    />
)

export default ThemeOverrides

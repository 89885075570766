import Logo from 'components/src/core/Logo'

import styles from './MemberCardTopBar.module.scss'

// eslint-disable-next-line react/display-name
export default () => (
    <div className={styles.wrapper}>
        <Logo className={styles.logo} />
    </div>
)

import { ComponentConfig } from 'components/src/utils/ComponentOverridesContext'

import AccountLoginPageLayout from './components/account-login-page/AccountLoginPageLayout'
import AccountHero from './components/AccountHero/AccountHero'
import AccountIcon from './components/AccountIcon'
import AppRouter from './components/AppRouter/AppRouter'
import BreadcrumbIcon from './components/BreadcrumbIcon'
import CartItemContainer from './components/cart/CartItemContainer'
import CartItemImage from './components/cart/CartItemImage'
import CartItemInfoContainer from './components/cart/CartItemInfoContainer/CartItemInfoContainer'
import CartTotalsFooterPrice from './components/cart/CartTotals/CartTotalsFooterPrice'
import CtaSelectSizeButton from './components/CtaSelectSizeButton'
import Logo from './components/Logo'
import MemberCardTopBar from './components/MemberCardTopBar'
import NotFoundBackgroundImages from './components/NotFoundPage/NotFoundBackgroundImages'
import PickupPointIcon from './components/PickupPointIcon'
import PostalAddressIcon from './components/PostalAddressIcon'
import ProductInfoHeader from './components/ProductInfo/ProductInfoHeader'
import SuccessPageBackgroundImages from './components/SuccessPage/SuccessPageBackgroundImages'
import SuccessPageButtons from './components/SuccessPage/SuccessPageButtons'
import TileSlider from './components/TileSlider'
import ThemeOverrides from './theme/ThemeOverrides'

const componentsConfig: ComponentConfig = {
    AccountHero,
    AccountIcon,
    AccountLoginPageLayout,
    AppRouter,
    BreadcrumbIcon,
    CartItemContainer,
    CartItemImage,
    CartItemInfoContainer,
    Logo,
    NotFoundBackgroundImages,
    PickupPointIcon,
    PostalAddressIcon,
    ProductInfoHeader,
    SuccessPageBackgroundImages,
    SuccessPageButtons,
    CartTotalsFooterPrice,
    TileSlider,
    CtaSelectSizeButton,
    ThemeOverrides,
    MemberCardTopBar,
}

export default componentsConfig
